.Navigation {
    & > ul {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;

        & > li {
            & > a {
                display: block;
                padding: 5px 15px;
            }
        }
    }
}