@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

body {
    font-family: 'Quicksand', sans-serif;
}

h1, h2, h3 {
    font-family: 'Fjalla One', sans-serif;
    font-weight: normal;
}

h1 {
    font-size: 48px
}
